var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: "更新用户身份证信息",
        width: 800,
        "ok-text": "确认",
        "cancel-text": "取消"
      },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "div",
        { staticClass: "popup-content" },
        [
          _c(
            "a-form-model",
            _vm._b(
              { ref: "ruleForm", attrs: { model: _vm.params } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-item",
                { attrs: { label: "身份证人像面" } },
                [
                  _c(
                    "a-upload",
                    {
                      key: _vm.imgKey,
                      attrs: {
                        action: _vm.ip + "/file/upload",
                        "list-type": "picture-card",
                        "file-list": _vm.fileListByFrontImage
                      },
                      on: {
                        preview: function($event) {
                          return _vm.handleShowBigPic($event, "front")
                        },
                        change: _vm.handleChangeFrontImg
                      }
                    },
                    [
                      _vm.fileListByFrontImage.length < 1
                        ? _c(
                            "div",
                            [
                              _c("a-icon", { attrs: { type: "plus" } }),
                              _c("div", { staticClass: "ant-upload-text" }, [
                                _vm._v("上传图片")
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "身份证国徽面" } },
                [
                  _c(
                    "a-upload",
                    {
                      key: _vm.imgKey,
                      attrs: {
                        action: _vm.ip + "/file/upload",
                        "list-type": "picture-card",
                        "file-list": _vm.fileListByBackImage
                      },
                      on: {
                        preview: function($event) {
                          return _vm.handleShowBigPic($event, "back")
                        },
                        change: _vm.handleChangeBackImg
                      }
                    },
                    [
                      _vm.fileListByBackImage.length < 1
                        ? _c(
                            "div",
                            [
                              _c("a-icon", { attrs: { type: "plus" } }),
                              _c("div", { staticClass: "ant-upload-text" }, [
                                _vm._v("上传图片")
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "身份证签发日期" } },
                [
                  _c("a-date-picker", {
                    staticClass: "ml-5",
                    on: { change: _vm.handleSelectedBeginTime }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "身份证过期日期" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      staticClass: "ml-5",
                      attrs: { name: "radioGroup", "default-value": 0 },
                      on: { change: _vm.handleChangeCardEndTime }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("非长期")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("长期")])
                    ],
                    1
                  ),
                  !_vm.isLongTimeStatus
                    ? _c("a-date-picker", {
                        on: { change: _vm.handleSelectedEndTime }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }