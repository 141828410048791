<template>
  <div class="change-king-tag-box" v-if="showPopup">
    <a-modal
        :width="500"
        v-model="showPopup"
        title="修改发拍保证金"
        ok-text="确认"
        cancel-text="取消"
        @cancel="handleCancel"
        @ok="handleConfirm"
    >
      <div class="model-flex">
        <a-input
            v-model="bondPrice"
            placeholder="请输入需要缴纳的保证金"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showPopup: false,
      id: '',
      bondPrice: undefined
    }
  },
  methods: {
    /**  显示弹窗 */
    show(data) {
      this.id = data.id
      this.bondPrice = data.price || undefined
      this.showPopup = true
    },
    async handleConfirm() {
      if (!this.bondPrice) this.$message.warn('请输入保证金')
      const res = await this.axios.post("/dq_admin/userShopInfo/updateMinDepositMoney", {
        id: this.id,
        minDepositMoney: this.bondPrice
      })
      if (res.status != 200) return;
      this.$message.success(res.message);
      this.$emit('successEdit')
      this.showPopup = false
    },
    handleCancel() {
      this.id = ''
      this.bondPrice = undefined
      this.showPopup = false
    }
  }
}
</script>
<style lang="scss" scoped>
</style>