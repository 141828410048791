<template>
  <a-modal
      v-model="showPopup"
      title="惩罚"
      ok-text="确认"
      cancel-text="取消"
      @cancel='resetForms'
      @ok="handleConfirmModal"
  >
    <a-form-model
        ref="ruleForm"
        :model="blackPenalizeForms"
        :rules="rules"
    >
      <a-form-model-item
          label="惩罚标题"
          prop="penalizeTitle"
      >
        <a-input
            placeholder='惩罚标题'
            v-model="blackPenalizeForms.penalizeTitle"
        ></a-input>
      </a-form-model-item>

      <a-form-model-item
          label="惩罚内容"
          prop="penalizeContent"
      >
        <a-textarea
            placeholder='惩罚内容'
            v-model="blackPenalizeForms.penalizeContent"
        ></a-textarea>
      </a-form-model-item>

      <a-form-model-item
          label="惩罚天数"
          prop="days"
      >
        <a-select
            v-model="blackPenalizeForms.days"
            :filter-option="untils.filterOption"
            showSearch
            placeholder='请选择'
        >
          <a-select-option value="30">1个月</a-select-option>
          <a-select-option value="90">3个月</a-select-option>
          <a-select-option value="180">6个月</a-select-option>
          <a-select-option value="365">12个月</a-select-option>
          <a-select-option value="3650">永久</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import untils from "@/untils";
export default {
  data() {
    return {
      untils,
      blackPenalizeForms: {
        penalizeTitle: undefined,
        penalizeContent: undefined,
        days: undefined
      },
      rules: {
        penalizeTitle: [{ required: true, message: "惩罚标题", trigger: "blur" }],
        penalizeContent: [{ required: true, message: "惩罚描述", trigger: "blur" }],
        days: [{ required: true, message: "惩罚时长", trigger: "blur" }],
      },
      showPopup: false,
    }
  },
  methods: {
    /** 展示表单 */
    show(id) {
      this.blackPenalizeForms.userId = id
      this.showPopup = true
    },
    /** 重置表单 */
    resetForms() {
      this.blackPenalizeForms = {

      }
    },
    /** 提交惩罚表单 */
    handleConfirmModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.postFormData();
        } else {
          return false;
        }
      })
    },
    /** 提交表单数据 */
    async postFormData() {
      const res = await this.axios.post("/dq_admin/userShopPenalize/addPenalize", this.blackPenalizeForms);
      const { message } = res;
      if (res.status == 200) {
        this.$message.success(message);
        this.$emit('blackSuccess');
        this.showPopup = false;
      } else {
        this.$message.success(message);
      }
    },
  }
}
</script>