<template>
  <a-modal
      v-model="showPopup"
      title="更新用户身份证信息"
      :width="800"
      ok-text="确认"
      cancel-text="取消"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <div class="popup-content">
      <a-form-model
          ref="ruleForm"
          :model="params"
          v-bind="layout"
      >
        <a-form-item label="身份证人像面">
          <a-upload
              :key="imgKey"
              :action="ip + '/file/upload'"
              list-type="picture-card"
              :file-list="fileListByFrontImage"
              @preview="handleShowBigPic($event, 'front')"
              @change="handleChangeFrontImg"
          >
            <div v-if="fileListByFrontImage.length < 1">
              <a-icon type="plus"/>
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item label="身份证国徽面">
          <a-upload
              :key="imgKey"
              :action="ip + '/file/upload'"
              list-type="picture-card"
              :file-list="fileListByBackImage"
              @preview="handleShowBigPic($event, 'back')"
              @change="handleChangeBackImg"
          >
            <div v-if="fileListByBackImage.length < 1">
              <a-icon type="plus"/>
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item label="身份证签发日期">
          <a-date-picker class="ml-5" @change="handleSelectedBeginTime" />
        </a-form-item>
        <a-form-item label="身份证过期日期">
          <a-radio-group class="ml-5" name="radioGroup" :default-value="0" @change="handleChangeCardEndTime">
            <a-radio :value="0">非长期</a-radio>
            <a-radio :value="1">长期</a-radio>
          </a-radio-group>
          <a-date-picker v-if="!isLongTimeStatus" @change="handleSelectedEndTime" />
        </a-form-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import {postUpdateUserCardInfo} from "@/views/cmsPage/shopManage/shopInfoList/_apis"
import {mapState} from "vuex"
import 'moment/locale/zh-cn';

export default {
  data() {
    return {
      imgKey: Math.random() * 10,
      showPopup: false,
      params: {
        userId: undefined,
        identityCardFrontImgurl: undefined,
        identityCardReverseImgurl: undefined,
        identityCardIssuance: undefined,
        identityCardExpire: undefined
      },
      defaultData: {
        id: undefined,
        userId: undefined,
        identityCardFrontImgurl: undefined,
        identityCardReverseImgurl: undefined,
      },
      fileListByFrontImage: [],
      fileListByBackImage: [],
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      isLongTimeStatus: 0,
    }
  },
  computed: {
    ...mapState(["ip"]),
  },
  methods: {
    /** 修改长期还是短期 */
    handleChangeCardEndTime(e) {
      if (e.target.value) {
        this.params.identityCardExpire = '99991231'
      } else {
        this.params.identityCardExpire = ''
      }
      this.isLongTimeStatus = e.target.value
    },
    /** 选择签发日期 */
    handleSelectedBeginTime(time, timeStr) {
      console.log(time, timeStr)
      this.params.identityCardIssuance = timeStr.replace(/-/g, "");
    },
    /** 选择结束日期 */
    handleSelectedEndTime(time, timeStr) {
      console.log(time, timeStr)
      this.params.identityCardExpire = timeStr.replace(/-/g, "");
    },
    /** 展示信息 */
    show(data) {
      this.defaultData = data
      this.showPopup = true
    },
    /** 确定更新 */
    async handleConfirm() {
      if (this.fileListByFrontImage.length < 1) return this.$message.info('身份证人像面不能为空')
      if (this.fileListByBackImage.length < 1) return this.$message.info('身份证国徽面不能为空')
      if (!this.params.identityCardIssuance) return this.$message.info('身份证签发日期不能为空')
      if (!this.params.identityCardExpire) return this.$message.info('身份证过期日期不能为空')
      this.params.identityCardFrontImgurl = this.fileListByFrontImage[0].url
      this.params.identityCardReverseImgurl = this.fileListByBackImage[0].url
      this.params.userId = this.defaultData.userId
      this.$loading.show()
      const res = await postUpdateUserCardInfo(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('更新成功')
      this.$emit('updateUserCardInfo', {
        id: this.defaultData.id,
        cardFront: this.params.identityCardFrontImgurl,
        cardBack: this.params.identityCardReverseImgurl,
      })
      this.showPopup = false
    },
    /** 关闭信息 */
    handleCancel() {
      this.params = {
        userId: undefined,
        identityCardFrontImgurl: undefined,
        identityCardReverseImgurl: undefined,
        identityCardIssuance: undefined,
        identityCardExpire: undefined
      }
      this.defaultData = {
        id: undefined,
        userId: undefined,
        identityCardFrontImgurl: undefined,
        identityCardReverseImgurl: undefined,
      },
      this.showPopup = false
    },
    /** 上传到身份证人像面 */
    handleChangeFrontImg({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.fileListByFrontImage = JSON.parse(JSON.stringify(fileList));
    },
    /** 上传到身份证国徽面 */
    handleChangeBackImg({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.fileListByBackImage = JSON.parse(JSON.stringify(fileList));
    },
    /** 展示大图 */
    handleShowBigPic(file, type) {
      const picturesList = (type === 'front' ? this.fileListByFrontImage : this.fileListByBackImage).map((row) => {
        return row.url;
      });
      const curIndex = picturesList.findIndex(el=>{
        return el === file.url
      })
      if (picturesList && picturesList.length > 0) {
        this.$previewImg({
          list: picturesList.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),
          current: curIndex,
          baseImgField: "img_url",
          showMute: false,
        });
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>