import {objArrMapObj} from "@/untils/otherEvent"

export const LIAN_LIAN_SHOP_STATUS_TYPE_LIST = [
    {name: '已登记或开户失败', value: 'ACTIVATE_PENDING'},
    {name: '审核中', value: 'CHECK_PENDING'},
    {name: '审核通过', value: 'REMITTANCE_VALID_PENDING'},
    {name: '待激活', value: 'ACTIVATE_PENDING_NEW'},
    {name: '正常', value: 'NORMAL'},
]

export const LIAN_LIAN_USER_STATUS_CLASS_TYPE = {
    ACTIVATE_PENDING: 'color-red',
    CHECK_PENDING: 'color-gray',
    REMITTANCE_VALID_PENDING: 'color-orange',
    ACTIVATE_PENDING_NEW: 'color-blue',
    NORMAL: 'color-green'
}

export const lianLianStatusTypeMapText = (value) => {
    return objArrMapObj('value', 'name', LIAN_LIAN_SHOP_STATUS_TYPE_LIST)[value]
}